import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import NumberFormat from 'react-number-format';
import classes from './TransactionDetailsCard.module.scss';
import currencies from '../../../data/currencies';

const TransactionDetailsCard = (props) => {

    var dateFormat = require('dateformat');
    var accounting = require("accounting");

    const transaction = props.selectedTransaction;
    let cardStyle = { top: 120, background: null };
    let triangleStyle = { top: 35, bottom: null, borderColor: null };

    switch (transaction.type) {
        case 'purchase': {
            cardStyle.background = '#C2D0D3';
            triangleStyle.borderColor = 'transparent #C2D0D3 transparent transparent';
            break;
        }
        case 'sale': {
            cardStyle.background = '#ECF2EF';
            triangleStyle.borderColor = 'transparent #ECF2EF transparent transparent';
            break;
        }
        default:
        //do nothing
    }

    let account_type = null
    switch (transaction.portfolio.user.typology) {
        case 'service_point': {
            account_type = "Service Point";
            break;
        }
        case 'business_account': {
            account_type = "Business Account";
            break;
        }
        case 'bank_account': {
            account_type = "Bank Account";
            break;
        }
        case 'private_account': {
            account_type = "Private Account";
            break;
        }
        default:
        //do nothing
    }

    const currencyCode = transaction.amountPurchasedCurrency || transaction.amountSoldCurrency;
    const currencyName = currencies[currencyCode].name.toUpperCase();

    let order_number = null
    if (typeof (transaction.amount) != "undefined") {
        const parent_order_amount = accounting.unformat(transaction.amountPurchasedCents || transaction.amountSoldCents)
        if (transaction.amount !== parent_order_amount) {
            order_number = <span>This order is part of order <b>{transaction.number}</b></span>
        }
        else {
            order_number = <span>{transaction.number}</span>
        }
    }
    else {
        order_number = <span>{transaction.number}</span>
    }


    return (
        <div className={classes.DetailsCard}
            style={cardStyle}>
            <div className={classes.Triangle} style={triangleStyle}></div>
            <div className={classes.Content}>
                <div className={classes.Title}>
                    OFFER DETAILS
                </div>
                <div className={classes.Section1}>
                    <div>
                        <span><b>Offer Placed: </b>{dateFormat(transaction.datetime, "mmmm d, yyyy HH:MM")}</span>
                        <span style={{ float: "right" }}><b>{transaction.typology} Order</b></span>
                    </div>
                    <div>
                        <span><b>Offer Number: </b>{order_number}</span>
                    </div>
                </div>
                <div className={classes.Section2}>
                    <div className={classes.Col1}>
                        <div>
                            <div className={classes.SubTitle}><b>Item Details</b></div>
                            <div>
                                <NumberFormat value={typeof (transaction.amount) != "undefined" ? transaction.amount : transaction.amountPurchasedCents || transaction.amountSoldCents}
                                    displayType={'text'}
                                    thousandSeparator=","
                                    decimalSeparator="." /> {currencyName} at
                            {" " + transaction.rate + " " + getSymbolFromCurrency(transaction.amountPaidCurrency) + "/" +
                                    getSymbolFromCurrency(transaction.amountPurchasedCurrency || transaction.amountSoldCurrency)}
                            </div>
                            <div>Order Total: <NumberFormat
                                value={typeof (transaction.amount) != "undefined" ? transaction.amount * transaction.rate : transaction.amountPaidCents}
                                displayType={'text'}
                                suffix={transaction.amountPaidCurrency === "ALL" ? " L" : null}
                                prefix={transaction.amountPaidCurrency === "ALL" ? null : transaction.amountPaidCurrencyl}
                                thousandSeparator=","
                                decimalSeparator="." />
                            </div>
                        </div>
                        <div>
                            <div className={classes.SubTitle}><b>Payment Method</b></div>
                            <div><b>Bank Transfer</b></div>
                            <div>Bank of Albania</div>
                            <div>IBAN: IT89 3704 0044 0532 0130 00</div>
                        </div>
                        <div>
                            <div style={{ marginTop: 15, marginBottom: 10, paddingBottom: 10, borderBottom: "1px solid #285a644d" }}><b>Status:</b></div>
                            {transaction.CI ?
                                <>
                                    <div style={{ color: "#9a2020" }}><b>Defaulted</b></div>
                                    <div>Paid: {transaction.completedPayment}</div>
                                </>
                                : <div>{transaction.paidToPortal ? "Paid" : "Unpaid"}</div>}

                        </div>
                        <div>
                            {/*<button className={classes.YellowButton}
                                style={{ width: 190, marginBottom: 7, float: "left" }}>ORDER HISTORY</button>
                            <button className={classes.YellowButton}
                                style={{ width: 190, float: "left" }}>ACCESS HISTORY</button>*/}
                        </div>
                    </div>
                    <div className={classes.Col2}>
                        <div className={classes.SubTitle}><b>User Information</b></div>
                        <div style={{ display: "flex" }}>
                            <div><b>Account Type</b></div>
                            <div style={{ marginLeft: 5 }}>
                                <div>{account_type}</div>
                            </div>
                        </div>
                        <div className={classes.UserInfo}>
                            <div className={classes.Col1}>
                                <div><b>{transaction.portfolio.user.firstName} {transaction.portfolio.user.lastName}</b></div>
                                <div>{transaction.portfolio.user.personalIdNumber} <br /> DOB: {dateFormat(transaction.portfolio.user.dateOfBirth, "dd/mm/yyyy")}</div>

                                {(transaction.portfolio.user.document.typology === "passport" || transaction.portfolio.user.document.typology === "id_card") ?
                                    <div>
                                        National {transaction.portfolio.user.document.typology === "passport" ? "Passport" : "Identity Card"} <br />
                                        {transaction.portfolio.user.document.issueEntity} <br />
                                        No: {transaction.portfolio.user.document.number} <br />
                                        Issued: {transaction.portfolio.user.document.issueDate} <br />
                                        Expires: {transaction.portfolio.user.document.expirationDate}
                                    </div>
                                    :
                                    null
                                }

                                {/*<button className={classes.YellowButton}
                                    style={{ width: 190 }}>VIEW CERTIFICATES</button>*/}
                            </div>
                            <div className={classes.Col2}>
                                <div>{transaction.portfolio.user.address} {transaction.portfolio.user.postalCode} <br /> {transaction.portfolio.user.city}, {transaction.portfolio.user.country}</div>
                                <div style={{ marginTop: 20 }}>{transaction.portfolio.user.email}<br /> {transaction.portfolio.user.cellphone}</div>

                                {/*<button className={classes.YellowButton}
                                    style={{ width: 190, marginTop: 10 }}>VIEW DOCUMENTS</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >);
}

export default TransactionDetailsCard;
