import React, { useState } from 'react';
import classes from './CurrencyTrader.module.scss';
import TransactionCard from './TransactionCard';
import TransactionDetailsCard from './TransactionDetailsCard';

const DetailsPage = (props) => {

    const transaction = props.transaction;
    const parentTransaction = props.parentTransaction;
    const [showDetails, setShowDetails] = useState(props.openAccordion ? false : true);
    const [selectedTransaction, setSelectedTransaction] = useState(transaction);

    const toggleAccordion = (lastItem, openAccordion) => {
        if (openAccordion) setShowDetails(false);
    }

    const showDetailsCard = (transaction) => {
        setShowDetails(!showDetails);
        setSelectedTransaction(transaction);
    }

    let transactionsList = [];
    //child orders
    if (parentTransaction.type === "purchase" && parentTransaction.transactions) {
        parentTransaction.transactions.forEach(transaction => {
            transaction.saleOrder.amount = transaction.amount
            transactionsList.push(transaction.saleOrder);
        });
    }
    if (parentTransaction.type === "sale" && parentTransaction.transactions) {
        parentTransaction.transactions.forEach(transaction => {
            transaction.purchaseOrder.amount = transaction.amount
            transactionsList.push(transaction.purchaseOrder);
        });
    }
    transactionsList.push(parentTransaction);//parent order

    let html = <TransactionCard
        items={transactionsList}
        showDetailsCard={showDetailsCard}
        toggleAccordion={toggleAccordion}
        openAccordion={props.openAccordion}
        showTrasactionDetailsCard={true}
        selectedTransaction={transaction}
    />;

    return (
        <div className={classes.Page}>
            <div className={classes.PageColumn}>
                <div className={classes.Title} style={{ marginBottom: 30 }}>
                    <i className="fas fa-arrow-left fa-2x" style={{ marginRight: 10, color: "#D39C3E" }} onClick={props.goToMainPage}></i>
                    <span>DETAILS</span>
                </div>
                {html}
            </div>
            {showDetails ?
                <TransactionDetailsCard selectedTransaction={selectedTransaction} /> : null}
        </div>
    )
}

export default DetailsPage;