import React, { useState } from 'react';
import classes from './MainPage.module.scss';
import { useMutation } from 'react-apollo';
import CreateRole from '../../../mutations/RoleManagement/CreateRole';
import RolesQuery from '../../../queries/RoleManagement/Roles';

const EditRole = (props) => {

    const [role, setRole] = useState(props.selectedRole);
    const [createRole] = useMutation(CreateRole, { refetchQueries: [{ query: RolesQuery }] });

    const changeStatus = (permissionName) => {
        let roleTemp = role;
        let permissionsTemp = role.permissions;
        let permission = permissionsTemp.filter(item => item.name === permissionName)[0];
        let index = permissionsTemp.findIndex(item => item.name === permissionName);
        if (permission.status === true) permission.status = false;
        else permission.status = true;
        permissionsTemp.splice(index, 1, permission);
        roleTemp.permissions = permissionsTemp
        setRole({ ...roleTemp, permissions: permissionsTemp });
    }

    const handleOnChangeName = (event) => {
        event.persist();
        const value = event.target.value;
        setRole({ ...role, name: value });
    }

    const handleOnSave = () => {
        if (role.name === "" || role.name === null) {
            props.handleSetMessage("Please enter the name of the role");
        }
        else {
            props.handleSetMessage("");
            let attributes = {};
            role.permissions.forEach(element => {
                if (element.name !== "userManagement") attributes[element.name] = element.status;
            });
            attributes["name"] = role.name;
            console.log(attributes)
            createRole({
                variables: {
                    role_id: role.id,
                    attributes: attributes
                }
            }).catch(error => {
                console.log(error);
                if (error.message === "GraphQL error: Invalid input: Name has already been taken") {
                    props.handleSetMessage("Name has already been taken");
                }
            });
        }
    }

    const permissionsHTML = role.permissions.filter(permission => permission.name !== "userManagement").map(permission =>
        <div key={permission.name} className={classes.Permission}>
            <b>{props.permissions.filter(item => item.name === permission.name)[0].displayName}</b>
            {permission.status === true ?
                <i className={["fas fa-toggle-on fa-lg", classes.ToggleIcon].join(' ')} onClick={() => changeStatus(permission.name)}></i> :
                <i className={["fas fa-toggle-on fa-lg fa-flip-horizontal", classes.LowOpacityIcon].join(' ')} onClick={() => changeStatus(permission.name)}></i>
            }
        </div>);

    const assignedToHTML = role.users.map((user, index) =>
        <div key={index} style={{ display: "flex", margin: "5px 0" }}>
            <div style={{ flex: "0 0 40%", textAlign: "right", marginRight: 10, color: "#D39C3E" }} onClick={() => props.editUser(user)}><i className="fas fa-pen"></i></div>
            <div style={{ flex: 1 }}>{user.firstName + " " + user.lastName}</div>
        </div>);

    return (<div className={classes.AddRole}>
        <div className={classes.RoleName}>
            <div className={classes.Label}>RENAME YOUR ROLE</div>
            <div>
                <input type="text" placeholder="Name" className={classes.TextInput} style={{ width: 345 }} value={role.name} onChange={handleOnChangeName} />
            </div>
        </div>

        <div className={classes.Permissions}>
            <div className={classes.Label}>SELECT PERMISSIONS</div>

            <div className={classes.PermissionList}>
                {permissionsHTML}
            </div>
        </div>

        <div className={classes.UsersWithRole}>
            <div className={classes.Label}>USERS ASSIGNED THIS ROLE</div>
            {assignedToHTML}
        </div>

        <div className={classes.ActionButton}>
            <button className={classes.YellowButton} onClick={handleOnSave}>SAVE</button>
            <button className={classes.WhiteButton} onClick={() => props.handleNavOnClick("roles")}>CANCEL</button>
        </div>
    </div>);
}

export default EditRole;
