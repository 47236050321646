import React, { Component } from 'react'
import './Dropdown_styles.css'
import { Multiselect } from 'multiselect-react-dropdown';


class MultiSelectDropdown extends Component {
    constructor(props) {
        super(props)
        this.multiselectRef = React.createRef();
        this.state = {
            items: this.props.items || [],
            showItems: false,
            selectedItem: null,
        }

    }

    resetValues() {
      this.multiselectRef.current.resetSelectedValues();
    }

    render() {

        return (
          <div style={{position:"relative"}}>
            <div className="select-box--arrow" style={{right:"10px", bottom:"9px", top:"auto"}}>
              <span className="select-box--arrow-up"></span>
            </div>
          <div className="multiselect_div">
          <Multiselect
            options={this.props.options}
            selectedValues={this.props.selectedValues}
            displayValue="name"
            closeOnSelect={false}
            closeIcon={false}
            placeholder={""}
            avoidHighlightFirstOption	={true}
            ref={this.multiselectRef}
            style={{
              chips: { color: "#D39C3E", background:"none", display:"flex", flexDirection:"row-reverse", justifyContent:"flex-end", padding:"0px", fontSize:"12px" },
              searchBox: { display:"flex", flexDirection:"column", border:"none" },
              inputField: {border:"0.5px solid #CCCCCC", height:"33px", cursor:"pointer"},
              optionContainer: {fontWeight:"bold"},
            }}
            onSelect={this.props.onSelect}
            onRemove={this.props.onRemove}
           />
           </div>
           </div>
        );
    }
}

export default MultiSelectDropdown
