import React from 'react';
import classes from './MainPage.module.scss';

const UserInfo = (props) => {

    const user = props.user;

    return (<div className={classes.UserLogInfo} style={{ fontSize: 14, height: 500 }}>
        <div className={classes.CloseIcon} onClick={props.toggleShowUserInfo}><i className="fas fa-times-circle"></i></div>
        <div className={classes.UserInfo}>
            <div style={{ font: "Bold 20px/9px Open Sans", marginBottom: 10 }}>{user.firstName + " " + user.lastName}</div>
            <div>{user.ID}</div>
            <div className={classes.Title}><b>{user.role.name}</b></div>
        </div>
        <div className={classes.Title}><b>Contact Information:</b></div>
        <div className={classes.Item}>{user.address}</div>
        {/* <div className={classes.Item}>kati i 6, ap 22, Njesia bashkiake nr.5</div> */}
        <div className={classes.Item}>{user.postalCode + " " + user.city + ", " + user.country}</div>
        <div className={classes.Title}><b>Email:</b></div>
        <div>{user.email}</div>
        <div style={{ display: "flex" }}>
            <div>
                <div className={classes.Title}><b>Phone:</b></div>
                <div>{user.cellphone}</div>
            </div>
        </div>
    </div >);
}

export default UserInfo;
