import React from 'react';
import { useDrag } from 'react-dnd';
import classes from './Accounts.module.scss';
import NumberFormat from 'react-number-format';

const DraggableCard = (props) => {

    var dateFormat = require('dateformat');

    const action = props.action;
    const account = props.account;
    const selected = account.cardSelected;

    let cardStyle = {
        boxShadow: "none",
        border: "0.5px solid #CCCCCC"
    }
    const toggleSelect = () => {
        props.toggleCardSelection(account.id);
    }

    const checkbox = <div className={classes.SelectCircle} style={props.selected ? { background: "#285A64" } : null} onClick={toggleSelect}></div>;

    return (<>
        {account.group === null && checkbox}
        {(props.showCheckbox && props.action === "edit") && checkbox}
        <div className={classes.AccountsCard} style={{ cardStyle }}>
            <div style={{ fontSize: 12 }}>
                <span>Since {account ? dateFormat(account.createdAt, "d/m/yyyy") : ""}</span>
                {account.typology !== "market_viewer" ? <span style={{ float: "right" }}>Total Value</span> : null}
            </div>
            <div style={{ fontSize: 20 }}>
                <span>
                    {account && account.globalName}
                </span>
                <span style={{ float: "right" }}>{account && (account.balances ? account.balances.filter(item => item.currency === 'ALL')[0].totalBalance : "")}</span>
            </div>
            <div style={{ fontSize: 12, display: "flex" }}>
                <span>Group: {account.group !== null ? account.group.name : "-"}</span>
                <div style={{ display: "flex", marginLeft: "auto" }}>
                    {account.typology !== "market_viewer" ? <span>Daily Limit: {account && <NumberFormat
                        value={account.tradingDailyLimit}
                        displayType={'text'}
                        thousandSeparator=","
                        decimalSeparator="." />}</span> : null}
                </div>
            </div>
        </div>
    </>
    );
}

export default DraggableCard;
