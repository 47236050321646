import React from 'react';
import classes from './MainPage.module.scss';
var dateFormat = require('dateformat');

const UserLogInfo = (props) => {

    const user = props.user;
    return (<div className={classes.UserLogInfo}>
        <div className={classes.CloseIcon} onClick={props.toggleShowLogInfo}><i className="fas fa-times-circle"></i></div>
        <div className={classes.UserInfo}>
            <div style={{ font: "Bold 20px/9px Open Sans", marginBottom: 10 }}>{user.firstName + " " + user.lastName}</div>
            <div style={{ fontSize: 14 }}>{user.ID}</div>
            <div style={{ fontSize: 14, margin: "20px 0" }}><b>{user.role.name}</b></div>
        </div>
        <div style={{ fontSize: 14 }}>
            <div style={{ marginBottom: 15 }}><b>Activity Log:</b></div>
            {
              props.user.activityLogs.length > 0
              ?
                props.user.activityLogs.map(activity => {
                 return  <div style={{ paddingLeft: 20, marginBottom: 10 }}><span style={{ marginRight: 20 }}>{dateFormat(new Date(activity.createdAt), "dd/mm/yyyy HH:MM")}</span><span>Logged into BackOffice</span></div>
               })
              :
                <div>There is no activity for this particular user.</div>
            }

        </div>

    </div >);
}

export default UserLogInfo;
