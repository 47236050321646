import React, { Component } from 'react'
import './Dropdown_styles.css'


class RolesDropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: this.props.items || [],
            showItems: false,
            selectedItem: this.props.selectedItem || null,
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    dropDown = () => {
        this.setState(prevState => ({
            showItems: !prevState.showItems,
        }));

    }

    selectItem = (item) => {
        this.setState({
            selectedItem: item.value,
            showItems: false
        })

        this.props.onSelectDropdown(item.value, item.id)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showItems: false
            })
        }
    }


    render() {
        return (
            <div ref={this.setWrapperRef} className="select-box--box">

                <div className="select-box--container" style={{ boxShadow: this.props.boxShadow && this.props.boxShadow }}>

                    <div className="select-box--selected-item" style={{ display: "flex", paddingTop: 0 }}>
                        <div style={{ flex: 1, textAlign: "center", fontSize: 20 }}>
                            <b>{this.state.selectedItem !== null ? this.state.selectedItem : "SELECT ROLE"}</b>
                        </div>
                        <div className="select-box--arrow" onClick={this.dropDown}>
                            <span className="select-box--arrow-up"></span>

                        </div>
                    </div>


                    <div style={{ display: this.state.showItems ? 'block' : 'none', cursor: "pointer", fontSize: 20, maxHeight: 133 }}
                        className="select-box--items"
                    >



                        {
                            this.state.items.map(item => <div
                                key={item.id || item.value}
                                onClick={() => this.selectItem(item)}
                                className={this.state.selectedItem === item ? 'selected' : ''}
                            >

                                <b>{item.value}</b>
                            </div>)


                        }

                        <div style={{ background: "#D39C3E", color: "white" }} onClick={this.props.createNewRole}><b>CREATE NEW ROLE</b></div>
                    </div>

                </div>
            </div>
        );
    }
}

export default RolesDropdown
